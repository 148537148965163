.bg-pattern {
  background: url("./assets/dot-grid.png") repeat;
  background-size: 400px 400px;
  animation: bg-scroll 10s linear infinite;
}

@keyframes bg-scroll {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 400px 400px;
  }
}

@media (min-height: 1000px) {
  .tall-hero-margin {
    margin-top: 16rem; /* Adjust as needed */
  }
}
